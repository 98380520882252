import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home-two",
  templateUrl: "./home-two.component.html",
  styleUrls: ["./home-two.component.scss"],
})
export class HomeTwoComponent implements OnInit {
  activePoint: string | null = null;

  constructor() {}

  ngOnInit(): void {}

  togglePoint(point: string): void {
    // Toggle the active point: if it's the same point, collapse it; otherwise, set it as active
    this.activePoint = this.activePoint === point ? null : point;
  }
}
