<!-- Banner Area -->
<div class="banner-area-two">
  <div class="container-fluid">
    <div
      class="row align-items-center"
      style="
        background-image: url('assets/img/home-1.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 30rem;
      "
    >
      <div
        class="col-lg-12 col-md-12"
        style="
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center;
        "
      >
        <div class="banner-content banner-two-content">
          <span style="color: white; font-weight: 500">
            Bienvenue chez DSS consulting, nous sommes une société spécialisée
            dans l'intégration des ERP dans le domaine de l'assurance. Nous
            accompagnons nos clients, principalement des compagnies et des
            courtiers, dans leur projet d'intégration et nous fournissons des
            solutions complètes et personnalisées afin de leur aider à atteindre
            leur objectif métier.
          </span>

          <div class="banner-btn" style="padding: 10px">
            <a routerLink="/contact" class="contact-btn">Contactez-nous</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Banner Area End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
  <div class="container">
    <div class="service-widget-title">
      <div class="section-title text-center">
        <span>Nos services</span>
        <h2>
          Notre expertise en conseil vous aidera à atteindre vos objectifs de
          manière efficace et efficiente.
        </h2>
      </div>
    </div>

    <div class="row pt-45 pb-20">
      <div class="col-lg-4 offset-lg-2 col-md-6">
        <div class="services-card">
          <div class="services-icon">
            <i class="mdi mdi-file-sign"></i>
          </div>
          <h3>Expertise sur le métier de l’assurance</h3>
          <p>
            Notre équipe est composée d'experts chevronnés qui possèdent une
            solide expérience dans les domaines de l'assurance IARD, de la santé
            et de la prévoyance.
          </p>
          <div class="services-card-bottom"></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="services-card">
          <div class="services-icon">
            <i class="mdi mdi-application-cog"></i>
          </div>
          <h3>Intégration de progiciel en assurance</h3>
          <p>
            Nous avons acquis une expertise solide sur les progiciels
            d'assurance, et notre équipe se compose de professionnels reconnus
            sur notre marché.
          </p>
          <div class="services-card-bottom"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Services Area End -->

<div class="project-area pb-70">
  <div class="container">
    <div class="project-title-two">
      <div class="section-title">
        <span>Nos références</span>
        <h2>Entreprises qui ont choisi de nous faire confiance.</h2>
      </div>
    </div>

    <div class="tab project-tab text-center">
      <div class="tab_content current active pt-45">
        <div class="tabs_item current">
          <div class="project-tab-item">
            <div class="row" style="justify-content: center">
              <div class="col-lg-3 col-md-6">
                <div class="project-card">
                  <a target="”_blank”" href="https://www.amf-sam.fr/"
                    ><img
                      src="assets/img/logo-amf-2.svg"
                      alt="Images"
                      height="50px"
                  /></a>
                  <div class="project-content project-content-bg">
                    <h3>
                      <a target="”_blank”" href="https://www.amf-sam.fr/"
                        >AMF</a
                      >
                    </h3>
                    <div class="content">
                      <a
                        target="”_blank”"
                        href="https://www.amf-sam.fr/"
                        class="project-more"
                        ><i class="flaticon-double-right-arrows-angles"></i
                      ></a>
                    </div>
                    <div class="project-card-bottom"></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="project-card">
                  <a target="”_blank”" href="https://www.groupe-zephir.fr/"
                    ><img
                      src="assets/img/logo-zephir-50.png"
                      height="50px"
                      alt="Images"
                  /></a>
                  <div class="project-content project-content-bg">
                    <h3>
                      <a target="”_blank”" href="https://www.groupe-zephir.fr/"
                        >Zéphir</a
                      >
                    </h3>
                    <div class="content">
                      <a
                        target="”_blank”"
                        href="https://www.groupe-zephir.fr/"
                        class="project-more"
                        ><i class="flaticon-double-right-arrows-angles"></i
                      ></a>
                    </div>
                    <div class="project-card-bottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Choose Area -->
<div class="choose-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 col-md-12">
        <div class="section-title">
          <span>Pourquoi choisir notre entreprise ?</span>
          <h2>Un partenaire de confiance pour vous accompagner</h2>
        </div>

        <div class="tab choose-tab">
          <ul class="tabs">
            <li><a href="#">Notre vision</a></li>
            <li><a href="#">Notre expertise</a></li>
          </ul>

          <div class="tab_content current active pt-45">
            <div class="tabs_item current">
              <div class="choose-item">
                <div class="row">
                  <div class="col-lg-5 col-md-12">
                    <div class="choose-item-img">
                      <img src="assets/img/vision-1.jpg" alt="Images" />
                    </div>
                  </div>

                  <div class="col-lg-7 col-md-12">
                    <div class="choose-item-content">
                      <p>
                        Nous croyons fortement en l'importance d'un système
                        d'information au service de la stratégie d'entreprise.
                        Notre objectif est d'atteindre un alignement optimal
                        entre votre stratégie d'entreprise et votre système
                        d'information. Nous contribuons, par notre expertise et
                        notre accompagnement à faciliter sa mise en oeuvre.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tabs_item current">
              <div class="choose-item">
                <div class="row">
                  <div class="col-lg-7 col-md-12">
                    <div class="choose-item-list">
                      <ul>
                        <!-- Métier Section -->
                        <li
                          (click)="togglePoint('metier')"
                          [ngClass]="{ active: activePoint === 'metier' }"
                        >
                          <i class="bx bx-check"></i>
                          Métier
                          <ul
                            class="choose-sub-item"
                            *ngIf="activePoint === 'metier'"
                          >
                            <li>
                              Notre équipe est constituée des experts ayant une
                              expérience approfondie en assurance vie et non
                              vie.
                            </li>
                            <li>
                              Nous adaptons nos services à vos besoins
                              spécifiques et vous assurons un suivi tout au long
                              du projet.
                            </li>
                          </ul>
                        </li>
                        <!-- Méthodologie Section -->
                        <li
                          (click)="togglePoint('methodologie')"
                          [ngClass]="{ active: activePoint === 'methodologie' }"
                        >
                          <i class="bx bx-check"></i>
                          Méthodologie
                          <ul
                            class="choose-sub-item"
                            *ngIf="activePoint === 'methodologie'"
                          >
                            <li>
                              Nous disposons de processus rigoureux pour mener à
                              bien votre projet, de la phase d'analyse à la mise
                              en production.
                            </li>
                          </ul>
                        </li>

                        <!-- Progiciel Section -->
                        <li
                          (click)="togglePoint('progiciel')"
                          [ngClass]="{ active: activePoint === 'progiciel' }"
                        >
                          <i class="bx bx-check"></i>
                          Progiciel
                          <ul
                            class="choose-sub-item"
                            *ngIf="activePoint === 'progiciel'"
                          >
                            <li>
                              Nos experts maîtrisent parfaitement les
                              fonctionnalités de CLEVA et peuvent vous
                              conseiller sur les meilleurs configurations pour
                              votre entreprise.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-lg-5 col-md-12">
                    <div class="choose-item-img">
                      <img src="assets/img/about/about-img.png" alt="Images" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Choose Area End -->

<!-- Solutions Area -->
<div class="solutions-area pt-100 pb-70">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-12 text-center">
        <div class="solutions-content">
          <div class="section-title">
            <span>Obtenez des solutions adaptées à vos besoins</span>
            <h2>Vous avez un besoin d'accompagnement ou d'assistance ?</h2>
            <h2>Vous avez un projet d'intégration ?</h2>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-12">
        <div class="solutions-btn text-center">
          <a routerLink="/contact" class="default-btn">Contactez-nous</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Solutions Area End -->
