import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppleNowComponent } from "./components/pages/apple-now/apple-now.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { ServicesStyleOneComponent } from "./components/pages/services-style-one/services-style-one.component";

const routes: Routes = [
  { path: "", component: HomeTwoComponent },
  { path: "apply-now", component: AppleNowComponent },
  { path: "services", component: ServicesStyleOneComponent },
  { path: "contact", component: ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
