import { Component } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-apple-now",
  templateUrl: "./apple-now.component.html",
  styleUrls: ["./apple-now.component.scss"],
})
export class AppleNowComponent {
  constructor(private http: HttpClient, private toastr: ToastrService) {}
  formData = {
    nom: "",
    prenom: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    file: "",
  };
  onSubmit(event: Event) {
    event.preventDefault();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    // Perform form submission logic here
    let form = new FormData();
    form.append("nom", this.formData.nom);
    form.append("prenom", this.formData.prenom);
    form.append("email", this.formData.email);
    form.append("phone", this.formData.phone);
    form.append("subject", this.formData.subject);
    form.append("message", this.formData.message);
    form.append("file", this.formData.file);
    if (
      this.formData.nom !== "" &&
      this.formData.prenom !== "" &&
      this.formData.email !== "" &&
      this.formData.phone !== "" &&
      this.formData.subject !== "" &&
      this.formData.message != ""
    ) {
      this.http.post<any>("https://api.dssconsulting.fr", form).subscribe(
        (response) => {
          this.toastr.success(
            "On va vous contacter le plus tôt possible",
            "Message envoyé"
          );
          this.formData.nom = "";
          this.formData.prenom = "";
          this.formData.email = "";
          this.formData.phone = "";
          this.formData.subject = "";
          this.formData.message = "";
          this.formData.file = "";
          // Handle the response here
        },
        (error) => {
          console.error("Error occurred while sending POST request:", error);
          this.toastr.error("Formulaire invalide", "Erreur!");
        }
      );
    } else {
      this.toastr.error("Formulaire invalide", "Erreur!");
    }
  }
  onFileSelected(event: any) {
    this.formData.file = event.target.files[0];
    // Perform further actions with the selected file
  }
}
