<!-- Inner Banner -->
<div class="inner-banner inner-bg1">
  <div class="container">
    <div class="inner-title text-center">
      <h3>Accueil</h3>
      <ul>
        <li><a routerLink="/">Accueil</a></li>
        <li><i class="bx bxs-chevron-right"></i></li>
        <li>À propos de nous</li>
      </ul>
    </div>
  </div>
</div>
<!-- Inner Banner End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <span>À propos de nous</span>
          <h2>Nous avons <b>20+</b> ans d'expérience</h2>
          <p>
            Bienvenue chez DSS consulting, une agence de conseil et de
            développement de logiciels de premier plan. Nous sommes spécialisés
            dans la fourniture de solutions complètes et personnalisées pour
            aider les entreprises à relever les défis complexes de l'ère
            numérique.
          </p>
          <p>
            Chez DSS consulting, nous comprenons que chaque organisation est
            unique et rencontre des défis spécifiques. C'est pourquoi notre
            équipe de consultants expérimentés et de développeurs de logiciels
            travaille en étroite collaboration avec nos clients pour fournir des
            solutions sur mesure qui favorisent la croissance, améliorent
            l'efficacité et libèrent leur plein potentiel.
          </p>

          <div class="about-btn">
            <a routerLink="/contact" class="get-btn">Demander un devis</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-img-2">
          <img src="assets/img/about/about-img.png" alt="Images" />

          <div class="about-dots">
            <img src="assets/img/about/about-dots.png" alt="Images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- About Area End -->
