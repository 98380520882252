import { Component, OnInit } from "@angular/core";
import { AnalyticsService } from "../../../services";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  currentYear: Number = new Date().getFullYear();
  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.analyticsService.trackEvent(
      "footer loaded",
      "footer loaded into view",
      "Loaded"
    );
  }
}
