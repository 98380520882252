import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { FooterComponent } from "./components/common/footer/footer.component";
import { NavbarComponent } from "./components/common/navbar/navbar.component";
import { ServicesStyleOneComponent } from "./components/pages/services-style-one/services-style-one.component";
import { AppleNowComponent } from "./components/pages/apple-now/apple-now.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    AppComponent,
    HomeTwoComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    ServicesStyleOneComponent,
    AppleNowComponent,
    AboutComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
