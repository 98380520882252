<!-- Inner Banner -->
<div class="inner-banner inner-bg9">
  <div class="container">
    <div class="inner-title text-center">
      <h3>Nos services</h3>
      <ul>
        <li><a routerLink="/">Accueil</a></li>
        <li><i class="bx bxs-chevron-right"></i></li>
        <li>Nos services</li>
      </ul>
    </div>
  </div>
</div>
<!-- Inner Banner End -->
<!-- Services Area -->
<div class="services-area pt-100 pb-70">
  <div class="container">
    <div class="service-widget-title">
      <div class="section-title text-center">
        <span>Nos services</span>
        <h2>
          Notre expertise en conseil vous aidera à atteindre vos objectifs de
          manière efficace et efficiente.
        </h2>
      </div>
    </div>

    <div class="row pt-45 pb-20">
      <div class="col-lg-4 offset-lg-2 col-md-6">
        <div class="services-card">
          <div class="services-icon">
            <i class="mdi mdi-file-sign"></i>
          </div>
          <h3>Expertise sur le métier de l’assurance</h3>
          <p>
            Notre équipe est composée d'experts chevronnés qui possèdent une
            solide expérience dans les domaines de l'assurance IARD, de la santé
            et de la prévoyance.
          </p>
          <div class="services-card-bottom"></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="services-card">
          <div class="services-icon">
            <i class="mdi mdi-application-cog"></i>
          </div>
          <h3>Intégration de progiciel en assurance</h3>
          <p>
            Nous avons acquis une expertise solide sur les progiciels
            d'assurance, et notre équipe se compose de professionnels reconnus
            sur notre marché.
          </p>
          <div class="services-card-bottom"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Services Area End -->
